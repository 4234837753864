import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setIntroStartModule } from '../state/introStartModule';
import Module360 from '../components/360/module360';
import myImage from '../assets/image/360/fitness.jpg';
import myImageMobile from '../assets/image/360/fitness_mobile.jpg';
import { setMenuColor } from '../state/menuColor';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';

const StyledWrapper = styled.div`
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	text-align: center;
	width: 100%;
	z-index: 1000;
`;
const fitnessPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setScaleIntroAnimation(false));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
	}, []);

	return (
		<StyledWrapper>
			<Module360 desktopImgSrc={myImage} mobileImgSrc={myImageMobile} isQrCode />;
		</StyledWrapper>
	);
};
export default fitnessPage;
